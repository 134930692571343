<template>
    <div class="wrap">
        <Connect v-if="isConnect" :spaceIp="spaceIp" @closeModal="isConnect = false" />
        <ConnectUpdate v-if="isConnectUpdate" :spaceIp="spaceIp" @closeModal="isConnectUpdate = false" />
        <ConnectFail v-if="isConnectFail" :errMsg="errMsg" @closeModal="isConnectFail = false" />
        <div class="space_pc">
            <section class="main myspace w_940">
                <div class="main_box">
                    <div class="main_box_title" style="margin-bottom: 0;">
                        <h1>스피커 연결</h1>
                        <div @click="clickPre()">
                            <svg
                                width="19"
                                height="19"
                                viewBox="0 0 19 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </div>
                    <div class="cs_main">
                        <div class="myspace_content frenchise">
                            <div class="search" v-if="!isSpaceIp">
                                <p style="color: #fff;">스피커 IP 주소</p><br/>
                                <input type="text" placeholder="연결할 스피커의 IP주소를 입력해주세요." v-model="spaceIp" @keyup.enter="checkSpaceIp('insert')"/>
                                <p class="btn_type" @click="checkSpaceIp('insert')">확인</p>
                            </div>
                            <div class="search" v-else>
                                <p style="color: #fff;">스피커 IP 주소</p><br/>
                                <input type="text" v-model="spaceIp" :disabled="!updateSpaceIp" />
                                <p class="btn_type" v-if="!updateSpaceIp" @click="updateSpaceIp = true">수정</p>
                                <div class="input_btn" v-else>
                                    <p
                                        id="store_search"
                                        style="margin-bottom: 0px"
                                        @click="cencelUpdateIP()"
                                    >
                                        취소
                                    </p>
                                    <p class="on" style="margin-bottom: 0px" @click="checkSpaceIp('update')">
                                        완료
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import Connect from "@/components/modal/ConnectSpeaker/Connect.vue";
import ConnectUpdate from "@/components/modal/ConnectSpeaker/ConnectUpdate.vue";
import ConnectFail from "@/components/modal/ConnectSpeaker/ConnectFail.vue";
import $ from "jquery";
import axios from "axios";
import VueCookies from "vue-cookies";

export default defineComponent({
  data () {
    return {
      spaceIp: "",
      isSpaceIp: false,
      isConnect: false,
      isConnectFail: false,
      isConnectUpdate: false,
      updateSpaceIp: false,
      errMsg: ''
    };
  },

  components: {
    Connect,
    ConnectFail,
    ConnectUpdate
  },

  mounted () {
    this.getSpaceIp();
  },

  methods: {
    cencelUpdateIP () {
      this.updateSpaceIp = false;
      this.getSpaceIp();
    },
    getSpaceIp () {
      const spaceId = localStorage.getItem("spaceId");
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/profile/selectSpaceIp", { spaceId }, { headers })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            if (result != null && result.spaceIP != null) {
              this.isSpaceIp = true;
              this.spaceIp = result.spaceIP;
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    insertSpaceIp (param) {
      const spaceId = localStorage.getItem("spaceId");
      const spaceIp = this.spaceIp;
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/profile/insertSpaceIp", { spaceId, spaceIp }, { headers })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            param === 'update' ? this.isConnectUpdate = true : this.isConnect = true;
            this.updateSpaceIp = false;
            this.isSpaceIp = true;
          }
        })
        .catch(err => {
          console.log(err);
          if (err.response.data.resultMsg === "이미 등록된 IP입니다.") {
            this.errMsg = "이미 등록된 IP입니다.";
            this.isConnectFail = true;
          }
        });
    },
    checkSpaceIp (param) {
      this.errMsg = '';
      const target = this.spaceIp;
      const ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      if (ipformat.test(target)) {
        const instance = axios.create();
        const headers = {
          'Content-Type': 'text/plain;charset=UTF-8',
          Accept: '*/*'
        };

        instance
          .post("http://localhost:10099/api/getStatus", { target }, { headers })
          .then(res => {
            res.data.success === true ? this.insertSpaceIp(param) : this.isConnectFail = true;
          })
          .catch(err => {
            this.isConnectFail = true;
            console.log(err);
          });
      } else {
        this.isConnectFail = true;
      }
    },
    clickPre () {
      history.back();
    },
    set () {
      $(".span_box").siblings('div').hide();
      $(".back_dim").hide();
      $(".tooltip").hide();
    },
    clickSetBox () {
      $(document).off("click").on("click", ".span_box", (e) => {
        $(e.currentTarget).siblings('div').fadeToggle();
      });
    },
    showTooltip () {
      $(document).off("click").on("click", ".mo_header_box h2 svg", (e) => {
        $(".tooltip").fadeIn(300);
      });
    },
    hideTooltip () {
      $(".tooltip").fadeOut(300);
    },
    getSettingM () {
      $(document).off("click").on("click", ".brand_setting .span_box", () => {
        $(".back_dim").show();
        $(".brand_setting_modal").css({ bottom: 0, transition: "all 0.5s" });
      });
    }
  }
});
</script>

<style scoped>
  .btn_type {
    position: absolute;
    top: 79%;
    right: 10px;
    transform: translateY(-60%);
    padding: 8px 16px;
    border-radius: 8px;
    background: #fff;
    color: #151515;
    cursor: pointer;
    transition: all 0.3s;
  }
  .input_btn {
    position: absolute;
    top: 79%;
    right: 10px;
    transform: translateY(-60%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input_btn p {
    padding: 8px 16px;
    background: transparent;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    transition: all .3s;
  }
  .input_btn p.on {
    background-color: #fff;
    color: #000;
  }
  .frenchise .search {
    position: relative;
    flex-direction: column;
    align-items: flex-start !important;
  }
  .space_pc .search_brand img {
      width: 80px;
      height: 80px;
      border-radius: 8px;
    }
  .space_mo .search_brand img {
    width: 60px;
    height: 60px;
    border-radius: 8px;
  }
  .brand-list-alert{
    color: rgba(255, 255, 255, 0.64);
    width: 100%;
    text-align: center;
    margin-top: 10%;
  }
  .search_brand > div > span#eorl {
    width:auto;
    border-radius: 50px;
    padding:2px 12px;
    font-size:14px;
    background: rgba(156, 255, 110, 0.16);
    color:#98DB78;
  }
  .search_brand > div > span#dhksfy {
    width:auto;
    border-radius: 50px;
    padding:2px 12px;
    font-size:14px;
    color: #ccc;background: rgba(255, 255, 255, 0.16);
  }
  .search_brand>div>div:nth-child(1) span#eorl {
    color: #98DB78;
  }
  @media all and (min-width: 501px) {
    .search_brand>div>p {
      max-width: 190px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
