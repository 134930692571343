<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <!-- <h3>{{ spaceIp }}</h3> -->
            <p class="single-file-content">
                연결된 스피커가 변경되었습니다.
            </p>
            <div class="single-file-btn">
                <p id="color-text" @click="$emit('closeModal')">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: 'ConnectUpdate',
  props: {
    spaceIp: String
  }
});
</script>
