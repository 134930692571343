<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <!-- <h3>{{ spaceIp }}</h3> -->
            <p class="single-file-content" v-if="errMsg === ''">
                유효하지 않은 주소입니다.<br/>
                스피커 IP 주소를 확인해주세요.
            </p>
            <p class="single-file-content" v-else>
                {{ errMsg }}
            </p>
            <div class="single-file-btn">
                <p id="color-text" @click="$emit('closeModal')">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: 'ConnectFail',
  props: {
    errMsg: String
  }
});
</script>
